
import {CoffeeBasket} from "@/scripts/models/coffee";
import {anyNull} from "@/scripts/utils";
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import FileUpload from "@/components/common/FileUpload.vue";
// @ts-ignore
import testBaskets from "@/data/baskets.json";
import {SolveRequest} from "@/scripts/models/request";
import UserTip from "@/components/common/UserTip.vue";
// import data from "@/data/solution_bn5_sn37_d6.json";

export default Vue.extend({
  name: "OptimizationSettings",
  props: {
    value: {
      type: Array,
      required: true,
    },
    basketFlowMultipliers: {
      type: Object,
      required: false,
    },
    autosave: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },

  components: {UserTip},
  data: function () {
    return {
      baskets: [] as CoffeeBasket[],
      search: "",
      headers: [
        {text: "SPS", value: "basketSpsNr"},
        {text: "Name", value: "basketName"},
        {text: "Type", value: "basketTypeId"},
        {text: "Caf Type", value: "cafType"},
        {text: "Min Curing (h)", value: "minCuringDuration_h"},
        {text: "Max Curing (h)", value: "maxCuringDuration_h"},
        {text: "Massenfaktor", value: "densityWeightScalingFactor", align: "start"},
        {text: "Durchflussfaktor", sortable: false, value: "basketFlowMultiplier"},
      ],
    };
  },
  created() {
    this.init();
  },
  watch: {},
  computed: {
    ...mapState(["backendUrl", "superuser", "availableSolveRequest", "selectedSolveRequest", "availableBackends"]),
  },
  methods: {
    ...mapMutations(["selectWeekData", "selectSolveRequest", "selectBackend"]),
    save() {
      console.log("BasketSettings.inputHandler")
      this.$emit("input", this.baskets);
    },
    inputHandler() {
      console.log("BasketSettings.inputHandler")
      if (this.autosave) this.save();
    },
    init() {
      if (this.value !== null) {
        this.baskets = JSON.parse(JSON.stringify(this.value)) as CoffeeBasket[];
      } else {
        this.baskets = [];
      }
    },
  },
});
