
import Vue from "vue";
import {mapGetters, mapMutations, mapState} from "vuex";
import Sidebar from "./components/common/Sidebar.vue";
import Login from "./components/common/Login.vue";
import {printDagoptASCII} from "./scripts/utils";
import {BackendInterface} from "./scripts/BackendInterface";
import {CoffeeProductionInputFileType} from "@/scripts/models/calculation";
import {} from "@/scripts/auth";
// @ts-ignore
// import siloAssignmentSolutionData from "@/data/data_solutions_solution_bn22_sn36_tn6_objMinSiloAllocAndScaleGrBalS.json";

export default Vue.extend({
  components: {Sidebar, Login},
  name: "App",
  data() {
    return {
      showLogin: location.hostname !== "localhost",
      showSidebar: false,
      userHasDefaultPassword: false,
    };
  },
  watch: {
    "$route.meta.hasSidebar": {
      immediate: true,
      handler: function (newVal, oldVal) {
        // console.log("hasSidebar", newVal, oldVal);
        this.showSidebar = newVal ?? true;
      },
    },
    user: {
      immediate: true,
      handler() {
        // console.log("user", this.user)
      }
    }
    // "backendInterface.needLogin": {
    //   immediate: true,
    //   handler: function (newVal, oldVal) {
    //     console.log("needLogin", newVal, oldVal)
    //     if (newVal) {
    //       this.$router.push({ name: "Login" });
    //     } else {
    //       this.$router.push({ name: "SARA Plan Auswahl" });
    //     }
    //   },
    // },
  },
  async created() {

    // check if this is running on localhost
    const onTestServer = location.hostname === "jde-test.dagopt.com";
    if (onTestServer) {
      if (prompt("Password") === "lifeissuffering") {
        console.log("Password correct")
      } else {
        console.log("Password incorrect, redirecting ...")
        window.location.href = "https://jde.dagopt.com"
      }

    }

    // console.log("APP")
    printDagoptASCII()

    // console.log("route", this.$route)
    // const bi = this.backendInterface as BackendInterface
    // const user = await bi.getUser()
    // await this.bi.refreshAuthToken()
    if (this.bi.isLoggedIn()) {
      // const user = await this.bi.getUserInfo()
      // console.log("user", user)
      // this.setUser(user)

      this.setLoggedIn(true)
      // const user = await bi.getUser()
      // this.setUser(user)
      await this.bi.setFrontendVersionIfNewer(this.version)
      const prodSite = await this.bi.getProductionSite()
      this.setProductionSite(prodSite)
    }
    this.userHasDefaultPassword = await this.bi.userHasDefaultPassword()
    // console.log("hasDefaultPassword", hasDefaultPassword)

    // console.log("prodSite", prodSite);

    // const version = await bi.getLatestFrontendVersion()
    // console.log("Latest Frontend Version:", version);
    // console.log("Current Frontend Version:", this.version);

  },
  async mounted() {
  },
  computed: {
    ...mapState(["user", "version", "superuser", "userType", "backendInterface", "productionSiteUniqueName"]),
    ...mapGetters(["needLogin"]),
    loggedIn(): boolean {
      return this.backendInterface.isLoggedIn()
    },
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },

    // showSidebar(): boolean {
    //   console.log("showSidebar", this.$route?.meta?.showSidebar !== false);
    //   return this.$route?.meta?.showSidebar !== false;
    // },
  },
  methods: {
    ...mapMutations(["toggleSuperuser", "setUserType", "setProductionSite", "setLoggedIn", "setUser"]),
    deleteRefreshToken() {
      this.backendInterface.deleteRefreshToken()
    },
    deleteAuthToken() {
      this.backendInterface.deleteAuthToken()
    },

  },
});
