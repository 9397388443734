import {
    CoffeeBasket,
    CoffeeBasketDailyValues,
    CoffeeSiloAllocation,
    CoffeeProductionCompliancePlanFull,
    CoffeeRoaster,
    CoffeeRoasterAvailability,
    CoffeeArticlePackagingLine
} from "@/scripts/models/coffee";
import {gasConsumptions} from "@/data/gasConsumptions";
// import {UserType} from "@/scripts/models/general";
import {CoffeeProductionSolverParameters} from "@/scripts/models/calculation";
import {DayOfWeekDateRange} from "@/scripts/models/date";
import {UserRole} from "@/scripts/auth";

export const GAS_M3_TO_KWH: number = 10.55

export const ROASTER_NAMES = [
    // "Jetzone 1",
    "Jetzone 3",
    "Jetzone 4",
    // "Neptune 6",
    "Jupiter 7",
    "Jupiter 8",
    "Jupiter 9",
    // "Jetzone 11"
] as const

export type RoasterName = `${'Jupiter' | 'Neptune' | 'Jetzone'} ${number}`

export interface GasConsumptionPerRoaster {
    // "Jetzone 1": number
    "Jetzone 3": number
    "Jetzone 4": number
    // "Neptune 6": number
    "Jupiter 7": number
    "Jupiter 8": number
    "Jupiter 9": number
    // "Jetzone 11": number
}

export interface GasConsumptionAggregation {
    average: number;
    min: number;
    max: number;
}

export interface GasConsumptionsPerBasket {
    basketSpsNr: string;
    // basketSapNr: string;
    isTestBasket: boolean;
    roasterUsedForCalculation: keyof GasConsumptionPerRoaster | keyof GasConsumptionAggregation;
    gasConsumptionPerRoaster: GasConsumptionPerRoaster;
    // gasConsumptionAggregations: GasConsumptionAggregation;
}

// NEW

export interface CoffeeBasketWeekdayValues {
    basketSpsNr: number
    weekdayValues: number[]
}


export interface AnalyzeGasConsumptionSettings {
    loadedPlans: { rg: any, tas: any },
    maxTotalGasConsumption: number,
    gasConsumptionsPerBasket: Record<string, GasConsumptionsPerBasket>,
}


export function calculateGasConsumptionPerBasket(basketRoasterGasConsumptions: BasketRoasterGasConsumption[]): Record<string, GasConsumptionsPerBasket> {
    const brgci = basketRoasterGasConsumptions;
    // console.log("basketRoasterGasConsumptions", basketRoasterGasConsumptions)
    // // convert gas consumptions to a readable format
    const round = (n: string | number) => Math.round(Number(n) * 100) / 100
    const consumptionsPerBasket: Record<string, GasConsumptionsPerBasket> = {};

    // const basketSpsNrs =
    // // const headers = gasConsumptions[0];
    for (const gc of brgci) {
        const basketExists = gc.basketSpsNr in consumptionsPerBasket
        if (!basketExists) {
            consumptionsPerBasket[gc.basketSpsNr] = {
                basketSpsNr: gc.basketSpsNr.toString(),
                // basketSapNr: gc.bas,
                isTestBasket: gc.isTestBasket,
                roasterUsedForCalculation: "average", // keyof consumptionPerRoaster; any of jetzone1, ..., or average
                gasConsumptionPerRoaster: {
                    // "Jetzone 1": 0, //round(gc[2]),
                    "Jetzone 3": 0, //round(gc[3]),
                    "Jetzone 4": 0, //round(gc[4]),
                    // "Neptune 6": 0, //round(gc[5]),
                    "Jupiter 7": 0, //round(gc[6]),
                    "Jupiter 8": 0, //round(gc[6]),
                    "Jupiter 9": 0, //round(gc[6]),
                    // "Jetzone 11": 0, //round(gc[9]),
                },
            }
        }
        // @ts-ignore
        consumptionsPerBasket[gc.basketSpsNr].gasConsumptionPerRoaster[gc.roasterName] = round(gc.gasConsumption_m3)
        //     let basketSpsNr: string = gc[0] as string;
        //     const isTestBasket = basketSpsNr.endsWith("(T)");
        //     if (isTestBasket) basketSpsNr = basketSpsNr.slice(0, -3).trim();
        //     const consumptionRoasterSlice = gc.slice(2, 10) as number[]
        //     const nonZeroValues = consumptionRoasterSlice.filter(d => d !== 0)
        //     consumptionsPerBasket[basketSpsNr] = {
        //         basketSpsNr,
        //         basketSapNr: gc[1] as string,
        //         isTestBasket,
        //         roasterUsedForCalculation: "average", // keyof consumptionPerRoaster; any of jetzone1, ..., or average
        //         gasConsumptionPerRoaster: {
        //             jetzone1: round(gc[2]),
        //             jetzone3: round(gc[3]),
        //             jetzone4: round(gc[4]),
        //             neptune6: round(gc[5]),
        //             jupiter7: round(gc[6]),
        //             jupiter8: round(gc[7]),
        //             jupiter9: round(gc[8]),
        //             jetzone11: round(gc[9]),
        //         },
        //         gasConsumptionAggregations: {
        //             min: nonZeroValues.length === 0 ? 0 : round(Math.min(...nonZeroValues)),
        //             max: nonZeroValues.length === 0 ? 0 : round(Math.max(...nonZeroValues)) || 0,
        //             average: round((nonZeroValues).reduce((a, v) => a += v, 0) / nonZeroValues.length),
        //         },
        //     };
    }
    // console.log("consumptionsPerBasket", consumptionsPerBasket)
    return consumptionsPerBasket
}

export interface CoffeeArticlePriorityItem {
    articleNr: string // "1680003",
    productionTimePriorityFactor: number // 1,
    productionDeviationPricePlus: number // 1,
    productionDeviationPriceMinus: number // 1
}

export interface BasketRoasterGasConsumptionItem {
    roasterName: string // "Jetzone 1",
    roasterCategory: string // "Jetzone",
    basketSpsNr: number // 6012,
    gasConsumption_m3: number // 47.1,
    testBasket: boolean // false
}


export interface CoffeeProductionPAPAInputSettings {
    createDiscreteTimeModel: boolean // true,
    roasterAvailabilities: CoffeeRoasterAvailability[],
    articlePriorities: CoffeeArticlePriorityItem[],
    gasConsumptions: BasketRoasterGasConsumptionItem[],
    packagingLines: CoffeeArticlePackagingLine[] 
    maxTotalEnergyKWh: number // 1000000,
    defaultGasConsumptionKWh: number // 527.5,
    productionPlanHandlingType: string // "UseOriginalArticlePlan",
    noProductionDeviations: boolean // false,
    objectiveWeightTotalRoasterEnergy: number // 100,
    objectiveWeightTotalRoasterTaskNum: number // 5,
    objectiveWeightTotalPackagingTaskNum: number // 0.1,
    objectiveWeightWeightedTaskStartTimeSum: number // 0.1,
    objectiveWeightTotalWeightedProductionDeviation: number // 1000,
    weeklyProductionHours: number // 168,
    productionSubTaskNum: number // 1,
    roasterSubtaskNum: number // 6,
    hoursInTimeStep: number // 24,
    firstDayStartHour: number // 6
}

export interface InputData_Gas {
    baskets: CoffeeBasket[],
    roasters: CoffeeRoaster[],
    basketDemands: CoffeeBasketDailyValues[],
    basketPreroastingWeights: null,
    siloAllocations: CoffeeSiloAllocation[]
    fulfilledDemands: {
        basketValues: Record<string, number>
    },
    inputFileIds: string[],
    dataRange: DayOfWeekDateRange,
    compliancePlan: CoffeeProductionCompliancePlanFull,
    inputSettings: CoffeeProductionPAPAInputSettings,
    solverParameters: CoffeeProductionSolverParameters
}

export interface SolveRequest_Gas {
    id: string | null,
    creationTime: string//2022-07-27T19:12:24.946,
    userType: UserRole //SUPERUSER,
    productionSiteUniqueName: string // JDE_Berlin,
    timeRange: DayOfWeekDateRange,
    inputData: InputData_Gas
}

export interface BasketRoasterGasConsumption {
    roasterName: string //"Neptune 6",
    roasterCategory: string //"Neptune",
    basketSpsNr: number //6231,
    isTestBasket: boolean //false,
    gasConsumption_m3: number //66.1659822779338
}
