import Vue from "vue";
import Vuex from "vuex";
// @ts-ignore
import translations from "@/data/translations.ts"
import { BackendInterface } from "@/scripts/BackendInterface";
// import { UserType } from "@/scripts/models/general";
import {SiloAssignmentInputFilter} from "@/scripts/models/calculation";
import {SolveRequest} from "@/scripts/models/request";
import { CoffeeProductionSite } from "@/scripts/models/coffee";
import {UserInfo} from "@/scripts/auth";

Vue.use(Vuex);

const devMode = location.hostname === "localhost";
const backendMap: Record<string, string> = {
  "jde.dagopt.com": "https://test.dagopt.com/jde",
  "jde-test.dagopt.com": "https://test.dagopt.com/jde-test",
  "localhost": "http://localhost:8090",
}
if(!(location.hostname in backendMap)) {
  throw new Error(`No backend defined for hostname ${location.hostname}`)
}
const backendUrl = backendMap[location.hostname]
// const backendUrl = "https://test.dagopt.com/jde"
const backendInterface = new BackendInterface(backendUrl)


export default new Vuex.Store({
  state: {
    version: "2.17.11",
    backendInterface: backendInterface,
    backendUrl: backendUrl,
    superuser: devMode,
    // userType: userType,
    // maxUserType: userType,
    reset: 0,
    // availableBackends,
    translations,
    productionSiteUniqueName: "JDE_Berlin",
    productionSite: null as null | CoffeeProductionSite,
    loggedIn: false,
    user: null as null|UserInfo,
    userUpdateTrigger: 0,
    // timeZone: "Europe/Berlin"
  },
  getters: {
    // needLogin: (state) => {
    //   return true;
    // }

  },
  mutations: {
    setLoggedIn(state, loggedIn: boolean) {
      state.loggedIn = loggedIn;
    },
    setUser(state, user: any) {
      state.user = user;
    },
    triggerReset(state) {
      state.reset++;
    },
    toggleSuperuser(state) {
      state.superuser = !state.superuser;
    },
    selectBackend(state, backendUrl: string) {
      state.backendUrl = backendUrl;
      state.backendInterface = new BackendInterface(backendUrl)
    },
    // setUserType(state, userType: UserType) {
    //   state.userType = userType
    //   const userTypeValues = {
    //     USER: 0,
    //     ADMIN: 1,
    //     SUPERUSER: 2,
    //   }
    //   if(userTypeValues[userType] > userTypeValues[state.maxUserType]) state.maxUserType = userType
    // },
    setProductionSite(state, productionSite: CoffeeProductionSite){
      state.productionSite = productionSite
    },
    loadUser(state) {
      state.backendInterface.getCurrentUserInfo().then((userInfo) => {
        state.user = userInfo;
        state.userUpdateTrigger++;
        // state.loggedIn = true;
        // state.userType = userInfo.userType;
      })
    }
  },
  actions: {},
  modules: {},
});
