
import Vue, {PropType} from "vue";
import {mapMutations, mapState} from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {SiloUtilizationState} from "@/scripts/models/dori";
// @ts-ignore
import Plotly from "plotly.js-dist";
import {CoffeeBasket} from "@/scripts/models/coffee";

interface TimestampedState {
  timestamp: Date
  state: SiloUtilizationState
}

export default Vue.extend({
  name: "SilosUtilization",
  components: {},
  props: {
    // basketSpsNr: {type: Number as PropType<number>},
    baskets: {type: Array as PropType<CoffeeBasket[]>, required: true},
    firstDayStartTime: {type: [String, Date] as PropType<string | Date>},
    siloUtilizationStates: {type: Array as PropType<SiloUtilizationState[]>},
    siloUtilizationStatesComputed: {type: Array as PropType<SiloUtilizationState[]>},
  },
  data() {
    return {
      plotId: Math.random().toString(36).substring(7),
      hoursPerTimeStep: 24,
      basketSpsNr: 6448,
      commonTraceProperties: {

      }
    };
  },
  created() {
  },
  mounted() {
    console.log("baskets", this.baskets)
    setTimeout(() => {
      this.plot()
    }, 0)
  },
  watch: {
    basketSpsNr() {
      this.plot()
    },
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
    startDate(): Date {
      return new Date(this.firstDayStartTime)
    },
    availableBasketSpsNrs(): Array<{value: number, text: string}> {
      const states = new Set()
      const statesComputed = new Set()
      for(const state of this.siloUtilizationStates){
        states.add(state.basketSpsNr)
      }
      for(const state of this.siloUtilizationStatesComputed){
        statesComputed.add(state.basketSpsNr)
      }

      const res: Array<{value: number, text: string, hasState: boolean}> = []
      for(const basketSpsNr of statesComputed.values()){
        res.push({
          value: Number(basketSpsNr),
          text: `${basketSpsNr}`,
          hasState: states.has(basketSpsNr),
        })
      }
      res.sort((a, b) => a.value - b.value)
      return res;

    },
    sortedStates(): TimestampedState[] {
      const items = this.siloUtilizationStates
          .filter(state => state.basketSpsNr === this.basketSpsNr)
          .map(state => ({
            timestamp: this.getStateTimestamp(state),
            state,
          }))
      items.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
      return items
    },
    sortedStatesComputed(): TimestampedState[] {
      const items = this.siloUtilizationStatesComputed
          .filter(state => state.basketSpsNr === this.basketSpsNr)
          .map(state => ({
            timestamp: this.getStateTimestamp(state),
            state,
          }))
      items.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
      return items
    },
    densityWeightScalingFactorPerBasket(): Record<string, number>{
      const res: Record<string, number> = {}
      for(const basket of this.baskets){
        res[basket.basketSpsNr] = basket.densityWeightScalingFactor
      }
      return res
    },
    densityWeightScalingFactor(): number {
      return this.densityWeightScalingFactorPerBasket[`${this.basketSpsNr}`] ?? 1
    },
  },
  methods: {
    ...mapMutations([""]),
    plot() {
      const data = [
        this.createTrace_reservedCapacity(),
        this.createTrace_fullCapacity(),
        this.createTrace_storedWeight("Silo Auslastungen [t](optimierung)", this.sortedStates, {visible: "legendonly"}),
        // this.createTrace_storedWeight_withDensityWeightScaling("Effektive Silo Auslastungen [t_bbe](optimierung)", this.sortedStates, {visible: "legendonly"}),
        this.createTrace_storedWeight("Silo Auslastungen [t](berechnet)", this.sortedStatesComputed),
        // this.createTrace_storedWeight_withDensityWeightScaling("Effektive Auslastungen [t_bbe](berechnet)", this.sortedStatesComputed),
      ]
      // console.log("data", data)
      const layout = this.createLayout()
      Plotly.newPlot(this.plotId, data, layout)
    },
    createLayout(){
      const layout = {
        // title: "Silo Utilization",
        xaxis: {
          // title: "Time",
          type: "date",
          tickformat: "%H:%M",
          tickmode: "array",
          // tickvals: this.getTickVals(),
          // ticktext: this.getTickText(),
        },
        yaxis: {
          title: "Ton",
          // range: [0, 1],
        },
        // margins
        margin: {
          l: 50,
          r: 50,
          b: 50,
          t: 0,
          pad: 10,
        },
        // height
        height: 300,
        legend: {
          orientation: "h",
        },
      }
      return layout
    },
    createTrace_reservedCapacity() {
      const items = this.sortedStatesComputed
      // console.log("n items", items.length)
      const x = items.map(item => item.timestamp)
      const y = items.map(item => item.state.capacity_ton/this.densityWeightScalingFactor)
      // console.log("capacity ton", y)
      // x.unshift(this.startDate)
      // y.unshift(NaN)
      return {
        x, y,
        name: "Reservierte Kapazität [t äquiv.]",
        type: "scatter",
        mode: "lines",
        line: {
          color: "rgb(0,0,0)",
          // dashed
          dash: "dash",
        },
        // no hover info
        hoverinfo: "none",
        showlegend: true,
      }
    },
    createTrace_fullCapacity() {
      const items = this.sortedStatesComputed
      const x = items.map(item => item.timestamp)
      const y = items.map(item => (item.state.capacity_ton+item.state.extendedCapacity_ton)/this.densityWeightScalingFactor)
      // x.unshift(this.startDate)
      // y.unshift(NaN)
      return {
        x, y,
        name: "Erweiterte Kapazität [t äquiv.]",
        type: "scatter",
        mode: "lines",
        line: {
          color: "rgb(0,0,200)",
          // dashed
          dash: "dash",
        },
        // no hover info
        hoverinfo: "none",
        showlegend: true,
      }
    },

    createTrace_storedWeight(title: string, items: TimestampedState[], options:any={}) {
      const x = items.map(item => item.timestamp)
      const y = items.map(item => item.state.storedWeight_ton)
      return {
        x, y,
        name: title,
        type: "scatter",
        mode: "lines+markers",
        hoverinfo: "text",
        hovertext: items.map(item => this.createHovertext(item)),
        ...options,
      }
    },
    createTrace_storedWeight_withDensityWeightScaling(title: string, items: TimestampedState[], options:any={}) {
      const x = items.map(item => item.timestamp)
      const y = items.map(item => item.state.storedWeight_ton * this.densityWeightScalingFactor)
      return {
        x, y,
        name: title,
        type: "scatter",
        mode: "lines+markers",
        line: {dash: "dashdot"},
        hoverinfo: "text",
        hovertext: items.map(item => this.createHovertext(item)),
        ...options,
      }
    },
    createHovertext(item: any): string {
      const state = item.state
      const weekday = item.timestamp.toLocaleString("de-DE", {weekday: "short"})
      const storedWeight_ton_percent = state.storedWeight_ton / state.capacity_ton * 100
      const storedWeight_ton_percentStr = `${storedWeight_ton_percent.toFixed(2)} %`
      return `
<b>Zeitstempel</b>: ${weekday}, ${item.timestamp.toLocaleString("de-DE")}<br>
<b>Reservierte Kapazität (SARA)</b>: ${state.capacity_ton.toFixed(2)} t<br>
<b>Erweiterte Kapazität (DORI)</b>: ${((state.capacity_ton ?? 0) + (state.extendedCapacity_ton ?? 0)).toFixed(2)} t<br>
<b>Gepeichertes Gewicht</b>: ${state.storedWeight_ton.toFixed(2)} t<br>
<b>Speicher Auslastung</b>: ${storedWeight_ton_percentStr}<br>
<b>Meßtyp</b>: ${state.sampleTimeTypes.join(", ")}<br>
--------------------------------------------------<br>
<b>Startzeit</b>: ${this.startDate.toLocaleString("de-DE")}<br>
<b>Zeitschritt</b>: ${state.timeStep}<br>
<b>Meßzeit (stunden)</b>: ${state.sampleTime_h.toFixed(2)} h<br>
<b>Meßzeit im Zeitschritt (stunden)</b>: ${state.sampleTimeInTimeStep_h.toFixed(2)} h<br>
`
    },
    getStateTimestamp(state: SiloUtilizationState) {
      // const hourOffset = state.timeStep * this.hoursPerTimeStep + state.sampleTimeInTimeStep_h
      const hourOffset = state.sampleTime_h
      // console.log("hourOffset", hourOffset)
      const newTime_ms = this.startDate.getTime() + hourOffset * 60 * 60 * 1000
      return new Date(newTime_ms)
    },
  },
});
