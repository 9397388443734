import { render, staticRenderFns } from "./CalculationSection.vue?vue&type=template&id=4c2b6bc9&scoped=true&"
import script from "./CalculationSection.vue?vue&type=script&lang=ts&"
export * from "./CalculationSection.vue?vue&type=script&lang=ts&"
import style0 from "./CalculationSection.vue?vue&type=style&index=0&id=4c2b6bc9&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c2b6bc9",
  null
  
)

export default component.exports