
import Vue, { PropType } from "vue";
import { mapMutations, mapState } from "vuex";
import { copy, debounce, formatTime} from "@/scripts/utils";
import { CoffeeRoasterAvailabilityExclusion, ExclusionOrigin, ExclusionReason } from "@/scripts/models/dori";
import { DayOfWeekDate, Weekday, weekdayEnumToGer } from "@/scripts/models/date";
import { MultiExclusion, SelectionItem, TimeItemRange } from "@/scripts/exclusions";
import Template from "../Template.vue";
// import { UserType } from "@/scripts/models/general";

export default Vue.extend({
  name: "AvailabilityExclusionSettings",
  components: { Template },
  props: {
    value: { type: Array as PropType<CoffeeRoasterAvailabilityExclusion[]> },
    startDate: { type: Object as PropType<DayOfWeekDate> },
    readonly: { type: Boolean as PropType<boolean>, default: false },
  },
  data() {
    return {
      // UserType,
      ExclusionOrigin,
      time: {
        showAddTimeDialog: false,
        // availableTimeRanges: [] as TimeItemRange[],
        availableHours: [...Array(25)].map((d, i) => i),
        availableMinutes: [...Array(4)].map((d, i) => i * 15),
        newAvailableTimeRange: {
          startTime: { hour: 6, minute: 0 },
          endTime: { hour: 14, minute: 0 },
        } as TimeItemRange,
        availableExclusionTimeRanges: [
          {
            text: "Frühschicht (06-14)",
            value: {
              startTime: { hour: 6, minute: 0 },
              endTime: { hour: 14, minute: 0 },
            },
          },
          {
            text: "Spätschicht (14-22)",
            value: {
              startTime: { hour: 14, minute: 0 },
              endTime: { hour: 22, minute: 0 },
            },
          },
          {
            text: "Nachtschicht (22-06)",
            value: {
              startTime: { hour: 22, minute: 0 },
              endTime: { hour: 30, minute: 0 },
            },
          },
          {
            text: "Alle Schichten (06-06)",
            value: {
              startTime: { hour: 6, minute: 0 },
              endTime: { hour: 30, minute: 0 },
            },
          },
          {
            text: "Ganzer Tag (00-24)",
            value: {
              startTime: { hour: 0, minute: 0 },
              endTime: { hour: 24, minute: 0 },
            },
          },
        ] as SelectionItem<TimeItemRange>[],
      },
      emittedExclusions: null as any,
      exclusions: [] as MultiExclusion[],
      exclusionReasons: [], //[ExclusionReason.UNKNOWN, ExclusionReason.MAINTENANCE, ExclusionReason.REGULAR_MAINTENANCE],
      availableRoasters: [
        // "Jetzone 1",
        "Jetzone 3",
        "Jetzone 4",
        // "Neptune 6",
        "Jupiter 7",
        "Jupiter 8",
        "Jupiter 9",
        // "Jetzone 11",
      ],
      inputProps: {
        textField: {
          dense: true,
          hideDetails: true,
          outlined: true,
        },
        select: {
          dense: false,
          hideDetails: true,
          outlined: true,
        },
      },
      debouncedEmit: () => {},
    };
  },
  async created() {
    this.exclusionReasons = await this.backendInterface.listRoasterExclusionReasons();
    // console.log("exclusionReasons", this.exclusionReasons);

    this.debouncedEmit = debounce(() => {
      console.log("debounced emit");
      this.emit();
    }, 200);
  },
  watch: {
    exclusions: {
      deep: true,
      handler() {
        this.debouncedEmit();
      },
    },
    value: {
      immediate: true,
      handler() {
        if (JSON.stringify(this.value) === JSON.stringify(this.emittedExclusions)) {
          console.warn("Detected emit-watcher loop. Exclusion settings will not be re-constructed from passed value.");
          return;
        }
        this.exclusions = MultiExclusion.fromCoffeeRoasterAvailabilityExclusions(this.value);

        // build available time ranges
        for (const ex of this.exclusions) {
          exTimeRangeLoop: for (const exTimeRange of ex.exclusionTimeRanges) {
            for (const availableTimeRange of this.time.availableExclusionTimeRanges) {
              const timeRangeExists = MultiExclusion.timeItemRangeEqual(exTimeRange, availableTimeRange.value);
              if (timeRangeExists) continue exTimeRangeLoop;
            }
            this.time.availableExclusionTimeRanges.push({
              text: this.timeRangeToString(exTimeRange),
              value: exTimeRange,
            });
          }
        }
      },
    },
  },
  computed: {
    ...mapState(["user", "backendInterface"]),
    showExclusionOrigin(){
      return false;
      // TODO: fix after modelType has been introduced
      // return userType === UserType.SUPERUSER
    },
    availableDates(): Array<{ text: string; value: Weekday }> {
      const weekdays = weekdayEnumToGer;
      const startDate = this.startDate;
      // const endDate = this.endDate;
      const weekdayStart = startDate.dayOfWeek;
      console.log("weekdayStart", weekdayStart);

      let started = false;
      const dates = [];
      for (const weekday in weekdays) {
        if (weekday === weekdayStart) started = true;
        if (started || true) {
          // const dayOfWeekDate = {
          //   year: startDate.year,
          //   weekNumber: startDate.weekNumber,
          //   dayOfWeek: weekday as Weekday,
          // };
          dates.push({
            // @ts-ignore
            text: weekdayEnumToGer[weekday],// this.getDayOfWeekDateString(dayOfWeekDate),
            value: weekday as Weekday,
          });
        }
      }

      return dates;
    },
    showAddTimeButton(): boolean {
      const isTimeRangeValid = (tr: TimeItemRange): boolean => {
        const startTimeSet = tr.startTime.hour !== null && tr.startTime.minute !== null;
        const endTimeSet = tr.endTime.hour !== null && tr.endTime.minute !== null;
        // ts is not acknowledging  the above null checks
        if (startTimeSet && endTimeSet) {
          // @ts-ignore
          return (
            // @ts-ignore
            tr.endTime.hour > tr.startTime.hour ||
            // @ts-ignore
            (tr.endTime.hour === tr.startTime.hour && tr.endTime.minute > tr.startTime.minute)
          );
        } else {
          return false;
        }
      };
      const doesAlreadyExist = (tr: TimeItemRange, timeRanges: TimeItemRange[]): boolean => {
        for (const timeRange of timeRanges) {
          if (this.timeRangesEqual(tr, timeRange)) {
            return true;
          }
        }
        return false;
      };
      // this.time.availableExclusionTimeRanges
      const valid = isTimeRangeValid(this.time.newAvailableTimeRange);
      const alreadyExists = doesAlreadyExist(
        this.time.newAvailableTimeRange,
        this.time.availableExclusionTimeRanges.map((d) => d.value)
      );
      return valid && !alreadyExists;
    },
  },
  methods: {
    ...mapMutations([""]),
    addExclusion() {
      this.exclusions.push(new MultiExclusion());
    },
    getDayOfWeekDateString(dayOfWeekDate: DayOfWeekDate, shorten: boolean = false) {
      const d = dayOfWeekDate;
      let weekdayGerman = weekdayEnumToGer[d.dayOfWeek]; //.slice(0,2)
      if (shorten) weekdayGerman = weekdayGerman.slice(0, 2);
      // return `KW${d.weekNumber} ${weekdayGerman}`;
      return `${weekdayGerman}`;
    },
    timeRangeToString(timeRange: TimeItemRange) {
      const startTimeString = formatTime(timeRange.startTime.hour!, timeRange.startTime.minute!);
      const endTimeString = formatTime(timeRange.endTime.hour!, timeRange.endTime.minute!);
      return `${startTimeString} - ${endTimeString}`;
    },
    addAvailableTime(exclusionSetting: MultiExclusion) {
      const newTimeRange = {
        text: this.timeRangeToString(this.time.newAvailableTimeRange),
        value: this.time.newAvailableTimeRange,
      };
      this.time.availableExclusionTimeRanges.push(newTimeRange);
      this.time.newAvailableTimeRange = {
        startTime: { hour: 6, minute: 0 },
        endTime: { hour: 14, minute: 0 },
      };
      this.time.showAddTimeDialog = false;
      exclusionSetting.addTimeRange(newTimeRange.value);
      console.log("this.time.availableExclusionTimeRanges", this.time.availableExclusionTimeRanges);
    },
    emit() {
      const res = [];
      for (const exclusion of this.exclusions) {
        res.push(...exclusion.toCoffeeRoasterAvailabilityExclusion());
      }
      // console.log("emitting", res);
      this.emittedExclusions = res;
      this.$emit("input", res);
    },
    timeRangesEqual(a: TimeItemRange, b: TimeItemRange) {
      return (
        a.startTime.hour === b.startTime.hour &&
        a.startTime.minute === b.startTime.minute &&
        a.endTime.hour === b.endTime.hour &&
        a.endTime.minute === b.endTime.minute
      );
    },
  },
});
