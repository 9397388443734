
import Vue, {PropType} from "vue";
import { mapMutations, mapState } from "vuex";
import {BackendInterface} from "@/scripts/BackendInterface";
import {
  getSolutionQualityColor,
  getSolutionQualityTranslation, isAnySuperuser,
  roundInverseMipGapValue,
  roundMipGapValue
} from "@/scripts/utils";
import {SolutionQuality} from "@/scripts/models/results";

export default Vue.extend({
  name: "SolutionQualityChip",
  components: {},
  props: {
    solutionQuality: {
      type: String as PropType<string|SolutionQuality>,
      required: true,
    },
    // can be number, NaN, string or null ...
    relativeMIPGap: {
      // type: Number as PropType<number>,
      required: false,
      // default: "NaN",
    },
    outlined: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    bi(): BackendInterface {
      return this.backendInterface as BackendInterface
    },
    isAnySuperuser(){
      return isAnySuperuser(this.user)
    },
    color(): string {
      return getSolutionQualityColor(this.solutionQuality)
    },
    displayText(): string {
      return getSolutionQualityTranslation(this.solutionQuality)
    },
    roundedInverseMipGapValue() {
      // @ts-ignore
      return roundInverseMipGapValue(this.relativeMIPGap ?? "NaN")
    },
    showSolutionQuality(){
      const hasMipGap = this.relativeMIPGap !== null && this.relativeMIPGap !== undefined
      const isSuperuser = this.isAnySuperuser
      return hasMipGap && isSuperuser
    },
  },
  methods: {
    ...mapMutations([""]),
  },
});
