import {
  CafType,
  CoffeeBasket,
  defaultSpringOptions,
  SpringErrorHandlingType,
  SpringOptions,
  SpringReturnType,
} from "./models/coffee";
import {Weekday, weekdayEnumToGer, weekdayToTimestepMap} from "@/scripts/models/date";
import {RoasterName} from "@/scripts/models/gas";
import {CoffeeProductionInputFileType, SiloAssignmentInputFile} from "./models/calculation";
import {BackendInterface} from "./BackendInterface";
import {CoffeeProductionModelType} from "./models/request";
import { SOLUTION_QUALITY_COLORS, SOLUTION_QUALITY_TRANSLATIONS, SolutionQuality } from "./models/results";
import {UserInfo, UserRole} from "@/scripts/auth";

export function generateOutputHash(n: number): string {
  return [...Array(n)]
    .map(() => Math.random().toString(16))
    .join("")
    .replace(/\./g, "");
}

// export function log(v: { [key: string]: any }) {
//   const [name, value] = Object.entries(v)[0];
//   console.log(`${name}:`, value);
// }

export function round(prec: number) {
  const k = 10 ** prec;
  return (x: number) => Math.round((x ?? 0) * k) / k;
}

export function formatSeconds(sec: number): string {
  if(sec>= 0 ){
    // format number of seconds to a string like "01:23:45"
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec - hours * 3600) / 60);
    const seconds = Math.floor(sec - hours * 3600 - minutes * 60);
    const hours_str = padToLength(hours, 2, "0");
    const minutes_str = padToLength(minutes, 2, "0");
    const seconds_str = padToLength(seconds, 2, "0");
    return `${hours_str}:${minutes_str}:${seconds_str}`;
  } else {
    return `00:00:00`;
  }
}

export function formatDate(date: Date|string|number){
  const d = new Date(date);
  return d.toLocaleString("de-DE")
  // return d.get
  // return new Intl.DateTimeFormat("de-DE", {}).format(d);
}

export function formatDateShort(date: Date|string|number){
  date = new Date(date);
  const d = date.toLocaleDateString("de-DE", {day: "2-digit", month: "2-digit", year: "2-digit"});
  const t = date.toLocaleTimeString("de-DE", {hour: "2-digit", minute: "2-digit"});
  return `${d}, ${t}`;
}

export function formatDateSuperShort(date: Date|string|number){
  // show only date, month and time
  date = new Date(date);
  const d = date.toLocaleDateString("de-DE", {day: "2-digit", month: "2-digit"});
  const t = date.toLocaleTimeString("de-DE", {hour: "2-digit", minute: "2-digit"});
  return `${d}, ${t}`;
}

export function formatTimeShort(date: Date|string|number){
  // show only weekday and time
  date = new Date(date);
  const d = date.toLocaleDateString("de-DE", {weekday: "short"});
  const t = date.toLocaleTimeString("de-DE", {hour: "2-digit", minute: "2-digit"});
  return `${d}, ${t}`;
}

export function camelCaseToWords(text: string): string {
  const result: string = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function rgbaArrayToString(rgba: number[]): string {
  return `rgba(${rgba.join(",")})`;
}

export function chunkArray(arr: any[], n: number): any[] {
  return arr.length != 0 ? [arr.slice(0, n), ...chunkArray(arr.slice(n), n)] : [];
}

export function extractTokensFromResponse(res: Response): string[] {
  const authHeader: string | null = res.headers.get("Authorization");
  if (authHeader == null) return [];
  const tokens: string[] = authHeader.split(" ");
  return tokens;
}

export function prepareSpringOptions(url: string, options: SpringOptions): SpringOptions {
  if (typeof options !== "object") {
    throw '[prepareSpringOptions] "options" is not an object!';
  }
  return {...defaultSpringOptions, ...options};
}



export async function parseSpringResponse(url: string, res: Response, options: SpringOptions) {
  // TODO: implement properly
  if(options.returnType == SpringReturnType.RAW){
    return res;
  }
  const resText = await res.text();
  if (options.returnType == SpringReturnType.JSON_OR_NULL || options.returnType == SpringReturnType.TEXT_OR_NULL) {
    // console.log("resText is null", resText === null)
    if (resText === null || resText === "") {
      return null;
    }
  }
  let returnValue: any = null;
  let resJson = null;
  const toJson = options.returnType == SpringReturnType.JSON || options.returnType == SpringReturnType.JSON_OR_NULL;
  if (!toJson) return resText;
  try {
    resJson = JSON.parse(resText);
    if ("errorMessage" in resJson || "error" in resJson) {
      const errorMessage = `[parseSpringResponse: ${url}] Response JSON could be parsed but contained an error. \n\n ${JSON.stringify(
        resJson,
        null,
        2
      )}`;
      if (options.errorHandling == SpringErrorHandlingType.RETURN_NULL_AND_LOG) {
        returnValue = null;
      } else if (options.errorHandling == SpringErrorHandlingType.RETURN_ERROR_AND_LOG) {
        returnValue = resJson;
      } else if (options.errorHandling == SpringErrorHandlingType.THROW_ERROR) {
        throw errorMessage;
      }
      if (options.errorHandling == SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW) {
        returnValue = null;
      } else {
        console.error(errorMessage);
      }
    } else {
      returnValue = resJson;
    }
  } catch (error) {
    const errorMessage = `[parseSpringResponse: ${url}] Response JSON could not be parsed. \n\n ${error}`;
    if (options.errorHandling == SpringErrorHandlingType.RETURN_NULL_AND_LOG) {
      returnValue = null;
    }
    if (options.errorHandling == SpringErrorHandlingType.RETURN_ERROR_AND_LOG) {
      returnValue = {error: errorMessage};
    }
    if (options.errorHandling == SpringErrorHandlingType.THROW_ERROR) {
      throw errorMessage;
    }
    if (options.errorHandling == SpringErrorHandlingType.RETURN_NULL_AND_SWALLOW) {
      returnValue = null;
    } else {
      console.error(errorMessage);
    }
  }
  return returnValue;
}

export function getResponseCode(res: Response): number {
  // console.log("res.status", res.status)
  return Number(res.status);
}

export function anyNull(...args: any): boolean {
  for (const arg of args) {
    if (arg === null || arg === undefined) return true;
  }
  return false;
}

export function anyNegative(...args: any): boolean {
  for (const arg of args) {
    if (arg < 0) return true;
  }
  return false;
}

export function anyNullOrNegative(...args: any): boolean {
  return anyNull(...args) || anyNegative(...args);
}

export function toNumber(s: string): number {
  return +s;
}

export function getEnumKeys(e: any) {
  return Object.keys(e).filter((fileType: string) => parseInt(fileType).toString() === "NaN");
}

export function hash_java(str: string): number {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function printDagoptASCII() {
  console.log(`
 ____  _____ _____ _____ _____ _____ 
|    \\|  _  |   __|     |  _  |_   _|
|  |  |     |  |  |  |  |   __| | |  
|____/|__|__|_____|_____|__|    |_|  
                                   
        OPTIMIZATION TECHNOLOGIES, 2024                
`);
}

export function padToLength(str: string | number, desiredLength: number, char: string = " ") {
  str = `${str}`;
  // if(str.length >= desiredLength) return str
  let diff = desiredLength - str.length;
  if (diff < 0) return str;
  while (diff--) str = char + str;
  return str;
}

export function parseTime(timeHours: number): string {
  if (!Number.isFinite(timeHours) || Number.isNaN(timeHours)) {
    return `--:--:--`
  }
  const hours = Math.floor(timeHours);
  const minutes = Math.floor((timeHours - hours) * 60);
  const seconds = Math.floor(((timeHours - hours) * 60 - minutes) * 60)
  const hours_str = padToLength(hours, 2, "0");
  const minutes_str = padToLength(minutes, 2, "0");
  const seconds_str = padToLength(seconds, 2, "0");
  return `${hours_str}:${minutes_str}:${seconds_str}`;
}

export const WEEKDAY_TRANSLATIONS = {
  // MONDAY: "Montag",
  // TUESDAY: "Dienstag",
  // WEDNESDAY: "Mittwoch",
  // THURSDAY: "Donnerstag",
  // FRIDAY: "Freitag",
  // SATURDAY: "Samstag",
  // SUNDAY: "Sonntag",
  [Weekday.MONDAY]: "Montag",
  [Weekday.TUESDAY]: "Dienstag",
  [Weekday.WEDNESDAY]: "Mittwoch",
  [Weekday.THURSDAY]: "Donnerstag",
  [Weekday.FRIDAY]: "Freitag",
  [Weekday.SATURDAY]: "Samstag",
  [Weekday.SUNDAY]: "Sonntag",
};

export function sumInMap(map: Record<string, number>, key: string, value: number): void {
  if (key in map) map[key] += value;
  else map[key] = value;
}

function createKeySorter(key: string, asc: boolean) {
  return (a: any, b: any) => (a[key] - b[key]) * (asc ? 1 : -1);
}

export interface SorterFunction {
  name: string;
  function: (a: any, b: any) => number;
}

export const timeSorters: Record<string, SorterFunction> = {
  timeStartAsc: {
    name: "Time Start (asc)",
    function: createKeySorter("timeStart", true),
  },
  timeStartDesc: {
    name: "Time Start (desc)",
    function: createKeySorter("timeStart", false),
  },
  timeFinishAsc: {
    name: "Time Finish (asc)",
    function: createKeySorter("timeFinish", true),
  },
  timeFinishDesc: {
    name: "Time Finish (desc)",
    function: createKeySorter("timeFinish", false),
  },
  timeTotalAsc: {
    name: "Total Duration (asc)",
    function: createKeySorter("timeTotal", true),
  },
  timeTotalDesc: {
    name: "Total Duration (desc)",
    function: createKeySorter("timeTotal", false),
  },
  // alphabetically: {
  //   name: "Alphabetically (asc)",
  //   function: (a: any, b: any) => (a as string).localeCompare(b as string);
  // },
};
export const palette = ["#81C784", "#e27d60", "#85dcba", "#e8a87c", "#41b3a3", "#c38d9e", "#fb7576"];

export function cyrb53(str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export class RandomNumberGenerator {
  count: number = 0;
  private range: number;

  constructor(private min: 0, private max: 1, private seed = "dagopt", private int = false) {
    this.range = this.max - this.min;
  }

  yield() {
    let {min, seed, range, int} = this;
    let n = cyrb53(seed, this.count++) / Number.MAX_SAFE_INTEGER;
    n = min + n * range;
    return int ? ~~n : n;
  }
}

export function throttle<T extends Function>(func: T, wait: number = 20) {
  let prev = 0;
  let callable = (...args: any) => {
    let now = Date.now();
    if (now - prev > wait) {
      prev = now;
      return func(...args);
    }
  };
  return <T>(<any>callable);
}

export function debounce<T extends Function>(func: T, wait: number = 20) {
  let timeout = 0;
  let callable = (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
  return <T>(<any>callable);
}

export function defer<T extends Function>(func: T, wait: number = 20) {
  let callable = (...args: any) => {
    setTimeout(() => func(...args), wait);
  };
  return <T>(<any>callable);
}

export function getRoasterNumber(roasterName: RoasterName) {
  return Number(roasterName.split(" ")[1]);
}

export function roasterSorter(roasterNameAccessor: (r: any) => string) {
  return (a: any, b: any) => {
    const nameA = roasterNameAccessor(a);
    const nameB = roasterNameAccessor(b);
    const roasterNumberA = +nameA.split(" ")[1];
    const roasterNumberB = +nameB.split(" ")[1];
    return roasterNumberA - roasterNumberB;
  };
}

export function roasterTypeSorter(roasterNameAccessor: (r: any) => string) {
  return (a: any, b: any) => {
    const nameA = roasterNameAccessor(a);
    const nameB = roasterNameAccessor(b);
    // const roasterNumberA = +nameA.split(" ")[1];
    // const roasterNumberB = +nameB.split(" ")[1];
    return nameA.localeCompare(nameB);
  };
}

export function id(a: any) {
  return a;
}

export function checkJdeEMailFormat(email: string): boolean {
  const regex = /^[A-Za-z].*?@jdecoffee\.com$/;
  const matches = regex.exec(email);
  return matches != null && matches.length > 0;
}

export function emailValid(emailAddress: string): boolean {
  return emailAddress != null && emailAddress != "" && /^\w+([\.-]?\w+)*$/.test(emailAddress);
}

export function emailValidationRule(emailAddress: string) {
  return emailValid(emailAddress) || "Bitte geben sie eine gültige Email Adresse an.";
}

function cafTypeValueFunction(cb: CoffeeBasket) {
  return (
    {
      [CafType.Decaf]: 1,
      [CafType.Mixed]: 2,
      [CafType.Caf]: 3,
    }[cb.cafType] ?? 0
  );
}

export function basketSorter(a: CoffeeBasket, b: CoffeeBasket, descending = false) {
  return (cafTypeValueFunction(a) - cafTypeValueFunction(b)) * (descending ? -1 : 1);
}

export function createColorFromNumber(basketSpsNr: number | string) {
  basketSpsNr = Number(basketSpsNr);
  let hue = 360 * (0.5 + Math.sin(basketSpsNr * 370) / 2);
  hue += 200;
  hue %= 360;
  return `hsla(${hue}, 70%, 80%, 90%)`;
}

export function changeTimezone(date: string | Date, timeZone: string) {
  if (typeof date === "string") date = new Date(date);
  return new Date(date.toLocaleString("en-US", {timeZone}));
}

export function copy(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function formatTime(hour: number, minute: number) {
  if (Math.floor(hour) !== hour) throw new Error(`Hour must be a whole number! Hour: ${hour}`);
  if (Math.floor(minute) !== minute) throw new Error(`Minute must be a whole number! Minute: ${hour}`);
  let hourString = hour.toFixed(0);
  let minuteString = minute.toFixed(0);
  if (hourString.length === 1) hourString = `0${hourString}`;
  if (minuteString.length === 1) minuteString = `0${minuteString}`;
  return `${hourString}:${minuteString}`;
}

export function getSummaryTitle(summary: any) {
  const tr = summary.timeRange;
  const translateWeekday = (weekday: any) => {
    return (weekdayEnumToGer as any)[weekday] ?? "";
  };
  const truncateWeekday = (weekday: string) => weekday.slice(0, 2).toUpperCase();
  const startDay = truncateWeekday(translateWeekday(tr.startDate.dayOfWeek));
  const endDay = truncateWeekday(translateWeekday(tr.endDate.dayOfWeek));
  const startDate = new Date(summary.timeRangeStart).toLocaleDateString("de-DE");
  const endDate = new Date(summary.timeRangeEnd).toLocaleDateString("de-DE");
  return `<b>KW ${tr.startDate.weekNumber}</b> ${startDay} - ${endDay}`;
}

export function getSummaryTimeRangeIndices(resultSummary: any): { startDayIndex: number; endDayIndex: number } {
  const startDate = resultSummary.timeRange.startDate;
  const endDate = resultSummary.timeRange.endDate;
  const indices = {
    startDayIndex: weekdayToTimestepMap[startDate.dayOfWeek],
    endDayIndex: weekdayToTimestepMap[endDate.dayOfWeek] + 1,
  };
  return indices;
}

export function clamp(x: number, min: number = 0, max: number = 1) {
  return Math.min(max, Math.max(min, x))
}

export function downloadCsv(fileName: string, fileContent: string) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(fileContent));
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export interface SiloAssignmentInputFileSummaryItem {
  name: string //"Compliance RG",
  value: string //fileToName(complianceRg),
  date: string //creationTime(complianceRg),
  id: string // complianceRg?.id
}
export function createInputFileSummary(inputFiles: SiloAssignmentInputFile[]){
  // if (this.solveRequest == null) return [];
  // const inputFiles = this.inputFiles as SiloAssignmentInputFile[]
  const planSorter = (a: SiloAssignmentInputFile, b: SiloAssignmentInputFile) => new Date(a.dataValidityStartTime).getTime() - new Date(b.dataValidityStartTime).getTime()
  const cellplan = inputFiles.find((f) => f.fileType === CoffeeProductionInputFileType.Cellplan);
  const fulfilledDemandsUpload = inputFiles.find(
    (f) => f.fileType === CoffeeProductionInputFileType.FulfilledDemands
  );
  const basket = inputFiles.find((f) => f.fileType === CoffeeProductionInputFileType.Basket);
  const roaster = inputFiles.find((f) => f.fileType === CoffeeProductionInputFileType.Roaster);

  const complianceRg = inputFiles
    .find((f) => f.fileType === CoffeeProductionInputFileType.ComplianceRg)
    // .sort(planSorter)
  const complianceTas = inputFiles
    .find((f) => f.fileType === CoffeeProductionInputFileType.ComplianceTas)
    // .sort(planSorter)
  // const complianceRg = complianceRgPlans;
  // const complianceTas = complianceTasPlans;

  const complianceRgPreroast = inputFiles
    .find((f) => f.fileType === CoffeeProductionInputFileType.ComplianceRgPreroast)
    // .sort(planSorter)
  const complianceTasPreroast = inputFiles
    .find((f) => f.fileType === CoffeeProductionInputFileType.ComplianceTasPreroast)
    // .sort(planSorter)
  // const complianceRgPreroast = complianceRgPlans;
  // const complianceTasPreroast = complianceTasPlans;
  // const tr = this.solveRequest.timeRange;
  // const displayDate = (date: DayOfWeekDate) => `${date.dayOfWeek} KW${date.weekNumber}`;
  // const displayTimeRange = `KW${tr.startDate.weekNumber}: ${tr.startDate.dayOfWeek} - ${tr.endDate.dayOfWeek}`;
  // console.log("complianceRgPlans", complianceRgPlans)
  const fileToName = (file?: SiloAssignmentInputFile | any) => {
    return file?.originalFilename ?? file?.baseFilename ?? "";
  };
  const creationTime = (file?: SiloAssignmentInputFile | any) => {
    return (`${file?.creationTime}`) ?? "";
  };
  const res = [
    // { name: "Berechnungszeitraum", value: displayTimeRange },
    // {name: "Zellenbelegung", value: fileToName(cellplan), date: creationTime(cellplan), id: cellplan?.id},
    // {
    //   name: "Röstkaffee-Chargierung",
    //   value: fileToName(fulfilledDemandsUpload),
    //   date: creationTime(fulfilledDemandsUpload),
    //   id: fulfilledDemandsUpload?.id
    // },
    {name: "Basket", value: fileToName(basket), date: creationTime(basket), id: basket?.id},
    {name: "Röster", value: fileToName(roaster), date: creationTime(roaster), id: roaster?.id},
    {
      name: "Compliance RG",
      value: fileToName(complianceRg),
      date: creationTime(complianceRg),
      id: complianceRg?.id
    },
    {
      name: "Compliance Tassimo",
      value: fileToName(complianceTas),
      date: creationTime(complianceTas),
      id: complianceTas?.id
    }
  ] as SiloAssignmentInputFileSummaryItem[];
  if(!!complianceRgPreroast && !!complianceTasPreroast){
    res.push(    {
        name: "Compliance RG [Vorröstung]",
        value: fileToName(complianceRgPreroast),
        date: creationTime(complianceRgPreroast),
        id: complianceRgPreroast?.id
      },
      {
        name: "Compliance Tassimo [Vorröstung]",
        value: fileToName(complianceTasPreroast),
        date: creationTime(complianceTasPreroast),
        id: complianceTasPreroast?.id
      },)
  }
  return res;
}

export function getSolutionQualityColor(solutionQuality: string): any {
  return SOLUTION_QUALITY_COLORS[solutionQuality as SolutionQuality];
}

export function getSolutionQualityTranslation(solutionQuality: string): any {
  return SOLUTION_QUALITY_TRANSLATIONS[solutionQuality as SolutionQuality]
}

export function roundMipGapValue(value: number | "NaN" | "Infinity"): string {
  if (value === "NaN" || value === "Infinity") {
    return '-'
  }
  return (value * 100).toFixed(1) + "%";
}

export function roundInverseMipGapValue(value: number | "NaN" | "Infinity"): string {
  if (value === "NaN" || value === "Infinity") {
    return '-'
  }
  return (100 - value * 100).toFixed(1) + "%";
}

export function findContrastColorRGB(rgbColor: string): string {
  const rgb = rgbColor.replace("rgb(", "").replace(")", "").split(",").map(Number);
  const brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);
  return (brightness > 125) ? 'rgba(0,0,0)' : 'rgb(255,255,255)';
}



export function isAnySuperuser(user: UserInfo): boolean {
  for(const modelType of ['sara', 'dori', 'papa']){
    // @ts-ignore
    const role = user?.[modelType+'UserRole'];
    if(role === UserRole.SUPERUSER) return true;
  }
  return false;
}



export function transformProblemForStatSolver(doriSolution: any, saraSolution: any, solveRequest: any){
  const WEEKDAYS = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]
  const formatSeconds = (seconds: number) => {
    // return 1h4m5s
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = Math.floor(seconds % 60);
    return `${hours}h${minutes}m${sec}s`;
  }

  const res = {
    //
    // DORI
    //
    doriSolutionInfo: {
      solutionStatus: doriSolution.solutionStatus,
      relativeMipGap: doriSolution.relativeMIPGap,
      solutionTime: formatSeconds(doriSolution.solutionTimeSeconds),
      solutionTimeSeconds: doriSolution.solutionTimeSeconds,
    },

    //
    // SARA INPUT
    //
    saraInput: {
      dateRange: solveRequest.inputData.dataRange,

      //
      // BASKETS
      //
      baskets: solveRequest.inputData.baskets,
      basketFlowMultipliers: solveRequest.inputSettings.basketFlowMultipliers.basketValues,

      //
      // ROASTERS
      //
      roasters: solveRequest.inputData.roasters,
      // TODO: roaster names
      roasterProductionStatuses: Object.values(solveRequest.inputData.compliancePlan.roasterProductionStatuses),
      basketRoastingProperties: solveRequest.inputData.roasters
        .flatMap((r: any) => r.basketInfos.map((bi: any) => ({
          roasterName: r.roasterName,
          roasterCategory: r.roasterCategory,
          ...bi,
        }))),
      roasterAvailabilities: solveRequest.inputSettings.roasterAvailabilities.map((ra: any) => {
        return {
          // type: ra.type, // "custom",
          roasterName: ra.roasterName, // "Jupiter 9",
          roasterCategory: ra.roasterCategory, // "Jupiter",
          startWeekNr: ra.startWeekNr, // 12,
          weeklyMaxRoastingHoursPerDay: ra.weeklyMaxRoastingHoursPerDay
            .map((d: any) => Object.fromEntries(d.map((dd: any, i: number) => [[WEEKDAYS[i]], dd]))), // 11
        }
      }),
      preroastingSettings: {
        RG: solveRequest.inputSettings.nextWeekPreroastingSettings.roastAndGroundPreroastingFactors.map((pr: any) => {
          return {
            minCuringTime_h: pr.minCuringTime_h, //6,
            weekdayPreroastingFactors: Object.fromEntries(pr.weekdayPreroastingFactors.map((w: any, i: number) => [WEEKDAYS[i], w])), //[]
          }
        }),
        TAS: solveRequest.inputSettings.nextWeekPreroastingSettings.tassimoPreroastingFactors.map((pr: any) => {
          return {
            minCuringTime_h: pr.minCuringTime_h, //6,
            weekdayPreroastingFactors: Object.fromEntries(pr.weekdayPreroastingFactors.map((w: any, i: number) => [WEEKDAYS[i], w])), //[]
          }
        })
      },

      //
      // SILOS
      //
      silos: solveRequest.inputData.siloAllocations.map((sa: any) => {
        return {
          siloSpsNr: sa.siloSpsNr,
          nominalCapacity_t: sa.nominalCapacity_t,
          usableCapacity_t: sa.usableCapacity_t,
          scaleGroupNr: sa.scaleGroupNr,
        }
      }),
      siloFlowMultipliers: solveRequest.inputSettings.siloFlowMultipliers.siloValues,
      silosCurrentlyStoredBasketAmounts: solveRequest.inputData.siloAllocations.map((sa: any) => {
        return {
          siloSpsNr: sa.siloSpsNr,
          storedWeight_t: sa.storedWeight_t,
          storedBaseBasketEquivalentWeight_t: sa.storedBaseBasketEquivalentWeight_t,
          storedBasketSpsNr: sa.storedBasketSpsNr,
          storedBasketAge_h: sa.storedBasketAge_h,
          contentLayers: sa.contentLayers,
        }
      }).filter((sa: any) => !!sa.storedBasketSpsNr && sa.storedBasketSpsNr !== -1),

      //
      // BLENDS & ARTICLES
      //
      articles: Object.values(solveRequest.inputData.compliancePlan.articles).map((a: any) => {
        return a
      }),
      blends: Object.values(solveRequest.inputData.compliancePlan.articles).map((a: any) => {
        return {
          blendSpsNr: a.blendSpsNr,
          blendSapNr: a.blendSapNr,
          decaf: a.decaf,
          chilled: a.chilled,
          basketContents: a.basketContents
        }
      }),

      //
      // MILLING
      //
      millingTasks: solveRequest.inputData.compliancePlan.millingAssignments.map((ma: any) => {
        return {
          millNumber: ma.millNumber, // 1,
          blendSpsNr: ma.blend.blendSpsNr, // 1,
          section: ma.roastAndGround ? "RG" : "TAS", // true,
          amount_t: ma.amount_t, // 0.541,
          taskPosition: ma.taskPosition, // -1,
          millingStartTime: ma.millingStartTime, // "1899-12-31T00:00:00",
          millingEndTime: ma.millingEndTime, // "1899-12-31T00:00:00"
        }
      }),
      millAvailabilityExclusions: solveRequest.inputData.compliancePlan.millAvailabilityExclusions,
      millProductionStatuses: Object.values(solveRequest.inputData.compliancePlan.millProductionStatuses),
    },

    //
    // SARA OUTPUT
    //
    saraOutput: {
      basketWeekdaySiloResults: saraSolution.results.basketWeekdaySiloResults,
      basketWeekdayRoasterResults: saraSolution.results.basketWeekdayRoasterResults,
      basketWeekdayResults: saraSolution.results.basketWeekdayResults,
      basketWeekdayPreroastingResults: saraSolution.results.basketWeekdayPreroastingResults,
      weekdayResults: saraSolution.results.weekdayResults,
      basketResults: saraSolution.results.basketResults,
    }
  }
  return res;
}
