
import Vue from "vue";
import {mapMutations, mapState} from "vuex";
import Gantt from "@/components/gantt/Gantt.vue";
import FileUpload from "@/components/common/FileUpload.vue";
import GasConsumptionWrapper from "@/components/gas/GasConsumptionWrapper.vue";
// @ts-ignore
import gasModelResults from "@/data/results/solution_2022_cw14_MONDAY_SUNDAY_discrete_cplex.json";
import {AnalyzeGasConsumptionSettings, calculateGasConsumptionPerBasket, GAS_M3_TO_KWH} from "@/scripts/models/gas";
import {
  MillingSubTaskResult,
  RoasterTaskResult,
  SolutionQuality,
  SOLUTION_QUALITY_COLORS,
  SOLUTION_QUALITY_TRANSLATIONS,
} from "@/scripts/models/results";
import {} from "@/scripts/gantt/gantt";
import ProductionPlanTable from "@/components/gas/ProductionPlanTable.vue";
import PackagingSummary from "@/components/gas/PackagingSummary.vue";
import GasConsumptionRange from "@/components/gas/GasConsumptionRange.vue";
import PackagingTaskPlot from "@/components/gas/TaskPlot.vue";
import RoastingSummary from "@/components/gas/RoastingSummary.vue";
import PackagingPlanOverview from "@/components/gas/PackagingPlanOverview.vue";
import AnalyzeGasConsumptionSection from "@/components/gas/AnalyzeGasConsumptionSection.vue";
import PapaSummaryGasAnalysis from "@/components/gas/PapaSummaryGasAnalysis.vue";
import PapaSettingsSection from "@/components/calculation/PapaSettingsSection.vue";
import DoriSettingsSection from "@/components/calculation/DoriSettingsSection.vue";

import PapaGasConsumptionSettings from "@/components/settings/PapaGasConsumptionSettings.vue";
import RoasterAvailabilitySettings from "@/components/settings/RoasterAvailabilitySettings.vue";
import PackagingLineSettings from "@/components/settings/PackagingLineSettings.vue";
import {CoffeeProductionDORISolveRequest, CoffeeProductionDORISummary} from "@/scripts/models/dori";
import {BackendInterface} from "@/scripts/BackendInterface";
import {CoffeeProductionModelType, InstantRequest, SolveRequest} from "@/scripts/models/request";
import {weekdayToTimestepMap} from "@/scripts/models/date";

import CoffeeProductionSARASummary from "@/scripts/SiloAssignmentSolution";
import SaraSummary from "@/components/cellplan/SaraSummary.vue";
import {getSummaryTitle} from "@/scripts/utils";
import DoriPerformanceDashboard from "@/components/common/DoriPerformanceDashboard.vue";
import {UserInfo, UserRole} from "@/scripts/auth";
import SaveSettingsDialog from "@/components/settings/SaveSettingsDialog.vue";
import DoriSummary from "@/components/dori/DoriSummary.vue";
import {formatSeconds,roundInverseMipGapValue} from "@/scripts/utils";

// import doriResult from "@/data/dori/solution_2022_cw44_MONDAY_SUNDAY_v1_gurobi.json"

enum FilterType {
  TAS_ONLY = "TAS_ONLY",
  RG_ONLY = "RG_ONLY",
  BOTH = "BOTH",
}

export default Vue.extend({
  name: "DoriSummaryView",
  components: {
    SaveSettingsDialog,
    DoriPerformanceDashboard,
    RoastingSummary,
    PackagingTaskPlot,
    PackagingSummary,
    ProductionPlanTable,
    Gantt,
    GasConsumptionWrapper,
    FileUpload,
    PackagingPlanOverview,
    GasConsumptionRange,
    AnalyzeGasConsumptionSection,
    PapaSummaryGasAnalysis,
    PapaSettingsSection,
    PapaGasConsumptionSettings,
    RoasterAvailabilitySettings,
    PackagingLineSettings,
    SaraSummary,
    DoriSettingsSection,
    DoriSummary,
  },
  props: {},
  data() {
    return {
      CoffeeProductionModelType,
      demoTime: "12:00",
      showSnackbar: false,
      snackbarText: "",
      breadcrumbs: [
        {text: "DORI", disabled: true, to: ""},
        {text: "Tagesplan", disabled: true},
      ],
      doriSummaryId: null as null | string,
      resultSummary: null as CoffeeProductionDORISummary | null, //null as CoffeeProductionDORISummary | null,
    };
  },
  created() {
  },
  mounted() {
    this.doriSummaryId = this.$route.params.id;
  },
  watch: {
    async doriSummaryId(v) {
      if (v === null) return;
      const bi = this.backendInterface as BackendInterface;
      this.resultSummary = await bi.dori_GetSolutionById(v);
      const solveRequest = await bi.dori_GetUsedSolveRequest(v);
      // console.log("solveRequest", solveRequest)
      // @ts-ignore
      this.resultSummary.solveRequest = solveRequest;
    }
  },
  computed: {
    ...mapState(["user", "backendInterface", "productionSiteUniqueName"]),
    title(): string {
      if (this.resultSummary !== null) return getSummaryTitle(this.resultSummary);
      else return "";
    },
    isDoriAdminOrMore(): boolean {
      const role = (this.user as UserInfo)?.doriUserRole
      return role === UserRole.ADMIN || role === UserRole.SUPERUSER;
    },
    isDoriSuperuser(): boolean {
      return (this.user as UserInfo)?.doriUserRole === UserRole.SUPERUSER;
    },
    timeRangeIndices(): { startDayIndex: number; endDayIndex: number } {
      if (this.resultSummary === null) return {startDayIndex: 0, endDayIndex: 7};
      const startDate = this.resultSummary.timeRange.startDate;
      const endDate = this.resultSummary.timeRange.endDate;
      const indices = {
        startDayIndex: weekdayToTimestepMap[startDate.dayOfWeek],
        endDayIndex: weekdayToTimestepMap[endDate.dayOfWeek] + 1,
      };
      return indices;
    },
    energyRangeDefinitions(): any[] {
      return [
        {name: "Gesamt", energyConsumption: this.resultSummary!.result.totalEnergyConsumption},
        {name: "Roast & Ground", energyConsumption: this.resultSummary!.result.roastAndGroundEnergyConsumption},
        {name: "Tassimo", energyConsumption: this.resultSummary!.result.tassimoEnergyConsumption},
      ];
    },
    creationTime(): string {
      return new Date(this.resultSummary?.creationTime ?? "").toLocaleString("de-DE");
    },
    pageInfo(): any {
      // HACK hide solution quality from users
      var solutionQualityTranslation = this.isDoriAdminOrMore ? SOLUTION_QUALITY_TRANSLATIONS[this.solutionQuality as SolutionQuality] : "";
      if(this.isDoriAdminOrMore){
        solutionQualityTranslation += " ("+roundInverseMipGapValue(this.resultSummary?.result.solverResult.relativeMipGap ?? NaN)+")";
      }
      const solutionQualityColor = SOLUTION_QUALITY_COLORS[this.solutionQuality as SolutionQuality];
      const pageInfo: any = {
        "Berechnet am": this.creationTime,
        Planzeitraum: `KW${this.resultSummary?.timeRange.startDate.weekNumber ?? "-"}`,
        // "Compliance RG": this.settings.loadedPlans.rg,
        // "Compliance TAS": this.settings.loadedPlans.tas,
        Lösungsqualität: this.isDoriAdminOrMore ? `<span class="solution-quality-chip" style="color: ${solutionQualityColor}">${solutionQualityTranslation}</span>` : `<span>${solutionQualityTranslation}</span>`,
        Lösungszeit: `<span>${formatSeconds((this.resultSummary?.result.solverResult.solutionTimeMS ?? 0) / 1000.0)}</span>`,
      };
      const parentSummaryId = this.resultSummary?.parentSummaryId
      if(!!parentSummaryId){
        pageInfo["Ursprungs-Plan"] = `<a target="_blank" href="/dori/plan/view/${parentSummaryId}">${parentSummaryId}</a>`
      }
      console.log("DORI VIEW", this.resultSummary)
      // @ts-ignore
      const saraSummaryId = this.resultSummary?.saraSummaryId
      if(!!saraSummaryId){
        pageInfo["SARA-Plan"] = `<a target="_blank" href="/sara/plan/view/${saraSummaryId}">${saraSummaryId}</a>`
      }
      return pageInfo
      // } else {
      //   return {};
      // }
    },
    solutionQuality(): string {
      return this.resultSummary?.solutionQuality ?? "";
    },
  },
  methods: {
    ...mapMutations([""]),
    uploadFile(e: any) {
      // console.log(e)
      // console.warn("not yet implemented")
      this.resultSummary = e;
      // this.resultSummary = new CoffeeProductionDORISummary(e);
    },
    async restart() {
      let source = this.$route.query.source;
      if (source !== "calculation" && source !== "selection") source = "calculation";
      let date = await this.backendInterface.convertInstantToDateTime({
        productionSiteUniqueName: this.productionSiteUniqueName,
        instant: this.resultSummary!.timeRangeStart,
      } as InstantRequest);
      date = date.slice(0, 10);
      await this.$router.push({path: "/dori/plan/" + source + "?date=" + date});
    },
    async recompute() {
      await this.$router.push({path: "/dori/plan/recompute/" + this.resultSummary?.id});
    },
    async improvePlan(){
      await this.$router.push({path: "/dori/plan/improve/" + this.resultSummary?.id});
    },
    async saveSettings() {
      console.warn("not yet implemented");
    },
  },
});
