import { render, staticRenderFns } from "./DoriRoasterTaskTable.vue?vue&type=template&id=d1b124a8&scoped=true&"
import script from "./DoriRoasterTaskTable.vue?vue&type=script&lang=ts&"
export * from "./DoriRoasterTaskTable.vue?vue&type=script&lang=ts&"
import style0 from "./DoriRoasterTaskTable.vue?vue&type=style&index=0&id=d1b124a8&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1b124a8",
  null
  
)

export default component.exports