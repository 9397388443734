
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import { GasConsumptionPerRoaster, GasConsumptionsPerBasket } from "@/scripts/models/gas";

export default Vue.extend({
  name: "GasConsumptionSettings",
  components: {},
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {};
  },
  created() {
    // console.log("settings", JSON.stringify(this.settings, null, 2));
  },
  watch: {},
  computed: {
    ...mapState(["user", "backendInterface"]),
    settings(): Record<string, GasConsumptionsPerBasket> {
      return this.value;
    },
    roasterNames(): { [k in keyof GasConsumptionPerRoaster]: string } {
      // const gc: GasConsumptionsPerBasket = Object.values(this.settings.gasConsumptionsPerBasket)[0]
      return {
        // "Jetzone 1": "Jetzone 1",
        "Jetzone 3": "Jetzone 3",
        "Jetzone 4": "Jetzone 4",
        // "Neptune 6": "Neptune 6",
        "Jupiter 7": "Jupiter 7",
        "Jupiter 8": "Jupiter 8",
        "Jupiter 9": "Jupiter 9",
        // "Jetzone 11": "Jetzone 11",
      };
    },
    sortedGasConsumptionsPerBasket(){
      // @ts-ignore
      const gc = [...Object.values(this.settings.gasConsumptionsPerBasket)]
      // @ts-ignore
      gc.sort((a,b) => a.basketSpsNr - b.basketSpsNr)
      console.log("gc", gc)
      return gc
    }
  },
  methods: {
    ...mapMutations([""]),
    format(v: number){
      return new Intl.NumberFormat("de-DE").format(v)
    },
  },
});
